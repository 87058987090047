<template>
  <div class="d-flex flex-column content landing">
    <div class="flex-grow-1 d-flex flex-md-row flex-column">
      <div class="w-50 promo position-relative d-none d-md-block">
        <div class="middle">
          <h2>{{ $t("general.signup-slogan") }}</h2>
        </div>
      </div>

      <div class="w-50 h-100 position-md-relative">
        <div class="mobile-auth">
          <div class="mobile-auth__hero d-md-none">
            <div class="mobile-auth__logo-wrapper">
              <Logo class="mobile-auth__logo" />
            </div>
          </div>

          <div class="mobile-auth__title">
            <span class="mobile-auth__font mobile-auth__font--title"> </span>
          </div>

          <div class="mobile-auth__content">
            <form novalidate class="form" @submit.prevent="submitForm">
              <CInput
                type="password"
                name="password"
                v-model="password"
                :label="$t('general.new-password')"
                :rules="'required'"
              />

              <CInput
                type="password"
                name="password_confirmation"
                v-model="passwordConfirm"
                :label="$t('general.confirm-password')"
                :rules="{
                  required: Boolean(password),
                  is: password,
                }"
              />

              <CButton
                type="submit"
                variant="primary"
                class="w-100 mb-3 text-uppercase"
                :disabled="hasErrors || requestInProgress"
              >
                {{ $t("general.save") }}
              </CButton>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="border-top p-3">
      <div class="text-center">
        <CLink to="/login" class="text-capitalize">
          {{ $t("general.auth.login.button.login") }}
        </CLink>
        <i class="bi-dot" />
        <CLink to="/signup" class="text-capitalize">
          {{ $t("general.auth.signUp.button.base") }}
        </CLink>
      </div>
    </div>
  </div>
</template>

<script>
import CButton from "@/features/ui/СButton.vue";
import CInput from "@/features/ui/СInput.vue";
import CLink from "@/features/ui/CLink.vue";

import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import Logo from "@/assets/svg/logo.svg";

export default {
  name: "PagePasswordReset",
  components: { Logo, CLink, CInput, CButton },
  mixins: [validationError, waitRequest],
  data() {
    return {
      password: "",
      passwordConfirm: "",
    };
  },
  computed: {
    hasErrors() {
      return Boolean(this.errors.items.length);
    },
  },
  methods: {
    async submitForm() {
      const isValid = await this.$validator.validateAll();

      if (!isValid || this.hasErrors || this.requestInProgress) return;

      const code = this.$route?.params?.code;
      const email = this.$route?.query?.email;

      const fields = {
        code,
        email,
        password: this.password,
        password_confirmation: this.passwordConfirm,
      };

      return this.waitRequest(() => {
        return this.$post(
          "/auth/recovery-password/reset",
          fields,
          () => {
            this.$router.replace("/");
          },
          () => {},
          this.checkErrors
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/vendors/_variables.scss";
.promo {
  background: $app-blue;
  color: $white;
  .middle {
    width: 100%;
    max-width: 344px;
    .logo {
      height: 4rem;
    }
    h2 {
      font-weight: normal;
    }
  }
}

.main {
  width: 344px;

  .logo {
    width: 80%;
    max-height: 4rem;
    margin: 0 auto;
  }
}

.mobile-auth {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__hero {
    background-image: url("/img/backgrounds/auth-login-background.webp");
    background-color: $black;
    height: em(160);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__logo-wrapper {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);
    height: em(61);
    width: em(204);
  }

  &__title {
    margin-top: em(20);
    margin-bottom: em(25);
  }

  &__title,
  &__content {
    padding-left: em(25);
    padding-right: em(25);
  }

  &__font {
    &--title {
      color: #000;
      font-family: $font-default;
      font-size: em(24);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

@include media-breakpoint-down(sm) {
  .w-50 {
    width: 100% !important;
  }
  .main {
    width: 90%;
  }
}
</style>
